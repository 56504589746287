<template>
  <div class="home">
    <Navigation />
    <div class="container">
      <h4>Manage Your Subscription</h4>
      <div class="row">
        <div id="servicebot-subscription-portal"></div>
      </div>
    </div>
  </div>
</template>
 
<script>
import Navigation from "@/components/Navigation.vue";
export default {
  components: {
    Navigation
  },
  data: () => ({
    first: null,
    last: null,
    publishableKey: process.env.VUE_APP_STRIPE_KEY,
    items: [
      {
        plan: process.env.VUE_APP_STRIPE_PLAN_MONTHLY,
        quantity: 1
      }
    ],
    email: null,
    serviceBotId: process.env.VUE_APP_SERVICEBOT_ID,
    hmac: null
  }),
  created() {
    //this.init(this.loadServiceBot);
    this.loadServiceBot();
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    //init(fn) {

    // have to do this nonsense to make sure auth0Client is ready
    //All of a suddent this became not necessary? Not sure whats going on here
    // var instance = this.$auth;
    // instance.$watch("loading", loading => {
    //   if (loading === false) {
    //     fn();
    //     console.log("Auth is ready");
    //   }
    // });
    //},
    async loadServiceBot() {
      console.log(this.$auth.user["https://theagentnest.com/hmac"]);
      const hmac = this.$auth.user["https://theagentnest.com/hmac"];
      const email = this.$auth.user.email;
      console.log(email);
      window.servicebotSettings = {
        servicebot_id: this.serviceBotId,
        type: "Portal",
        service: "Agent Nest Portal",
        options: {
          hideCancelDropdown: true,
          debug: true,
          behavior: {
            subscription_portal: {
              showChangePlan: false
            }
          }
        },
        email: email,
        hash: hmac
      };
      (function() {
        var s = document.createElement("script");
        s.src =
          "https://js.servicebot.io/embeds/servicebot-billing-settings-embed.js";
        s.async = true;
        s.type = "text/javascript";
        //var x = document.getElementsByTagName("script")[0];
        //x.parentNode.insertBefore(s, x);
        document.head.appendChild(s);
      })();
    }
  }
};
</script> 
<style scoped>
.logout {
  margin-left: 15px;
  background-color: #f19953;
  border-color: #f19953;
}
.cancel {
  margin-left: 15px;
  background-color: #ed6a5e;
  border-color: #ed6a5e;
}
.button-row {
  margin-bottom: 25px;
}
#servicebot-subscription-portal
{
  width:90%;
}
</style>